const ImageArray = [
  { image: require("./1.jpg") },
  { image: require("./2.jpg") },
  { image: require("./3.jpg") },
  { image: require("./4.jpg") },
  { image: require("./5.jpg") },
  { image: require("./6.jpg") },
  { image: require("./7.jpg") },
  { image: require("./8.jpg") },
  { image: require("./9.jpg") },
  { image: require("./10.jpg") },
  { image: require("./11.jpg") },
  { image: require("./12.jpg") },
  { image: require("./13.jpg") },
  { image: require("./14.jpg") },
  { image: require("./15.jpg") },
  { image: require("./16.jpg") },
  { image: require("./17.jpg") },
  { image: require("./18.jpg") },
  { image: require("./19.jpg") },
  { image: require("./20.jpg") },
  { image: require("./21.jpg") },
  { image: require("./22.jpg") },
  { image: require("./23.jpg") },
  { image: require("./24.jpg") },
  { image: require("./25.jpg") },
  { image: require("./26.jpg") },
  { image: require("./27.jpg") },
  { image: require("./28.jpg") },
  { image: require("./29.jpg") },
  { image: require("./30.jpg") },
  { image: require("./31.jpg") },
  { image: require("./32.jpg") },
  { image: require("./33.jpg") },
  { image: require("./34.jpg") },
  { image: require("./35.jpg") },
  { image: require("./36.jpg") },
  { image: require("./37.jpg") },
  { image: require("./38.jpg") },
  { image: require("./39.jpg") },
  { image: require("./40.jpg") },
  { image: require("./41.jpg") },
  { image: require("./42.jpg") },
  { image: require("./43.jpg") },
];
export default ImageArray;
